html {
  scroll-behavior: auto !important;
}

/*fonts*/
@font-face {
  font-family: 'domaine';
  src: url(fonts/Domaine.ttf);
}

@font-face {
  font-family: 'galvji';
  src: url(fonts/galvji.ttf);
}

@font-face {
  font-family: 'gothambold';
  src: url(fonts/GothamBold.otf);
}

@font-face {
  font-family: 'MaisonNeueBook';
  src: url(fonts/MaisonNeueBook.ttf);
}

@font-face {
  font-family: 'MontserratBold';
  src: url(fonts/MontserratBold.ttf);
}

.ff-gothambold {
  font-family: 'gothambold' !important;
}

.ff-domaine {
  font-family: 'domaine' !important;
}

.ff-montserratbold {
  font-family: 'MontserratBold' !important;
}

.primary-bg {
  background-color: #d9b297 !important;
}

.transparent-bg {
  background-color: #fff0 !important;
}

.body-bg {
  background-color: #ebd5c4 !important;
}

.text-nav2 {
  color: #336D8E;
}

.fs-nav1 {
  font-size: 15px;
}

.nav1-item {
  border-right: 2px solid #A21C32;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
}

.nav1-itemAr {
  border-left: 2px solid #A21C32;
  border-right: unset;
}

.fs-8 {
  font-size: .7rem;
}

.scroll-mt {
  scroll-margin-top: 180px;
}

.row .col-4:last-child {
  border: none !important;
}

/* .pt-scrollspy{
  scroll-padding-top: 300px; 
} */
.image-container {
  position: relative;
  display: inline-block;
  width: 100%;
}

.background-image {
  display: block;
  width: 100%;
  /* Adjust as needed */
  height: auto;
  /* Maintain aspect ratio */
}

.top-banner {
  max-height: 300px;
  object-fit: cover;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to top, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.3));
  /* Adjust colors and opacity as needed */
}

.overlay-image {
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  /* Adjust the size of the overlay image as needed */
  /* width: 150px; */
}

#dropdown-language {
  background-color: unset !important;
  border: unset !important;
}

.dropdown-img {
  width: 50px;
  height: 40px;
}

.dropdown-toggle::after {
  display: none !important;
}

.btn-feedback {
  background-color: #00000030 !important;
}

.feedback-icon {
  width: 30px !important;
  height: 30px !important;
  -webkit-filter: brightness(0) invert(1) grayscale(1);
  filter: brightness(0) invert(1) grayscale(1);
}

.h-scroll {
  overflow-x: auto;
  flex-wrap: nowrap !important;
  scrollbar-width: thin;
}

.h-nav2 {
  height: 55px;
}

.h-35 {
  height: 35px !important;
}

.nav-btn {
  width: 10px;
}

.body {
  position: relative;
  z-index: 1;
  background-color: #ebd5c4 !important;
  margin-top: -35px;
}

.list-group-item {
  background-color: #fff0 !important;
  border: unset !important;
  color: #fff !important;
}

.navbar2 .col {
  padding-left: 0;
  padding-right: 0;
  line-height: 25px;
  white-space: nowrap;
}

.navbar2 .col:first-child:before {
  padding-left: 0px;
  content: none;
}

.navbar2 .col::before {
  content: "•";
  position: relative;
  left: 0;
  color: #336D8E;
  margin-left: 10px;
}

.navbar2 .colAr::before {
  margin-right: 10px;
  margin-left: unset;
}

.active-nav1 {
  color: #336D8E !important;
}

.active-scroll-spy {
  color: #A21C32 !important;
}

.card-title {
  color: #BE6440 !important;
  font-family: 'domaine';
  font-size: 16px !important;
}

.card-text {
  font-family: 'galvji' !important;
  color: #336D8E;
  white-space: pre-line;
}

.card-price {
  font-family: 'gothambold' !important;
  color: #A21C32 !important;
  white-space: nowrap !important;
}

.vr {
  border-left: 1px solid #fff;
  height: 35px;
  margin-top: 5px !important;
  opacity: 0.6 !important;
  background-color: unset !important;
  padding: 0px !important;
}

.h-product-card {
  min-height: 495px;
}

.text-cal {
  font-family: 'galvji';
  color: #336D8E;
  font-size: 10px;
}

.text-allergens {
  font-family: 'galvji';
  color: #336D8E;
  font-size: 16px;
  text-align: center;
}

.text-breakfast {
  font-family: 'galvji';
  color: #336D8E;
  font-size: 16px;
  text-align: center;
}

.alert-nav1 {
  height: 55px !important;
}

.topN2-55 {
  top: 55px !important;
}

.home-btn:hover {
  background-color: #f8f9fabf !important;
}

.d-mobile {
  display: none;
}

@media only screen and (max-width: 450px) {
  .h-product-card {
    min-height: 320px;
  }

  .h-nav2 {
    height: 30px;
  }
}

@media only screen and (max-width: 500px) {
  .h-product-card {
    min-height: 355px;
  }

  .card-text {
    font-size: 0.6rem !important;
  }

  .text-allergens {
    font-family: 'galvji';
    color: #336D8E;
    font-size: 0.6rem;
    text-align: center;
  }

  .text-breakfast {
    font-family: 'galvji';
    color: #336D8E;
    font-size: 1rem;
    text-align: center;
  }

  .nav1-item {
    font-size: 14px;
    line-height: 14px;
  }

  .d-desktop {
    display: none;
  }

  .d-mobile {
    display: block;
  }

  .card-footer {
    min-height: 66px;
  }
}